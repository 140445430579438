import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/indexPage/index.vue')
    },
    {
        path: '/pulish',
        name: 'pulish',
        component: () => import('@/views/pulishPage/index.vue')
    },
    {
        path: '/entcom',
        name: 'entcom',
        component: () => import('@/views/entcomList/index.vue')
    },
    {
        path: '/entcompy',
        name: 'entcompy',
        component: () => import('@/views/entcomList/comListPage.vue')
    },

    {
        path: '/detail',
        name: 'detail',
        component: () => import('@/views/detailPage/index.vue')
    },
    {
        path: '/header',
        name: 'header',
        component: () => import('@/components/headerCom/index.vue')
    },
    {
        path: '/footer',
        name: 'heafooterder',
        component: () => import('@/components/footerCom/index.vue')
    },
    // 404页面
    {
        path: '/:catchAll(.*)',
        name: 'not-found',
        component: () => import('@/views/noFound/404Page.vue'),
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
]

const router = new VueRouter({
    routes
})

export default router
